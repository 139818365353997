<div class="forgot-password-component h100">

    <div fxLayout="column" fxLayoutAlign="center center" class="h100">

        <div>
            <img src="../../../assets/img/logo.png" alt="">
        </div>

        <div>

            <form [formGroup]="passwordForm" mat-dialog-content class="loginBox" fxLayout="column"
                fxLayoutAlign="center center">

                <mat-form-field class="w100" appearance="fill" floatLabel="always">
                    <mat-label>Your email</mat-label>
                    <input type="text" placeholder="Enter your email..." formControlName="email" name="name" matInput />
                </mat-form-field>

                <div fxLayoutAlign="center center" class="w100 mt24">
                    <button mat-flat-button class="loginBtn" (click)="sendEmail(passwordForm)" mat-stroked-button>
                        SEND
                    </button>
                </div>

                <div class="greenButton mt14 cursor" [routerLink]="['/sign-in']">
                    Log in
                </div>
            </form>

            <div *ngIf='loading' fxLayoutAlign="center " class="w100 mt40 spinner">
                <mat-spinner></mat-spinner>
            </div>

        </div>
    </div>
</div>
<div class="login-error-container h100" fxLayout="column" fxLayoutAlign="center center">
    <h2>Error</h2>
    <div>
        Failed to login! Check password or username!
    </div>
    <div>
        <button class="btn blueColor cancelBtn cursor" (click)='onClose()'>
            Try again
        </button>
    </div>
</div>

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-reqistration-error',
  templateUrl: './reqistration-error.component.html',
  styleUrls: ['./reqistration-error.component.css']
})
export class ReqistrationErrorComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ReqistrationErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
  }

  closePopup(){
    this.dialogRef.close();
  }

}

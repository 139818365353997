import { Component, HostListener, OnInit } from '@angular/core';
import { AuthenticationService } from './services/auth.service';
import { User } from './shared/models/user';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    title = 'Brake';
    currentUser: User;
    constructor(
        private authService: AuthenticationService,
    ) { 
        document.onkeydown = function (e) {
          if (e.keyCode == 123) {
              return false;
          }
          if (e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
              return false;
          }
          if (e.ctrlKey && e.shiftKey && e.keyCode == 'C'.charCodeAt(0)) {
              return false;
          }
          if (e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
              return false;
          }
          if (e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
              return false;
          }
          function devToolsOpened(e){
            e.preventDefault();
        }
        window.addEventListener('keydown', function(e) {
        
            if (
                // CMD + Alt + I (Chrome, Firefox, Safari)
                e.metaKey == true && e.altKey == true && e.keyCode == 73 ||
                // CMD + Alt + J (Chrome)
                e.metaKey == true && e.altKey == true && e.keyCode == 74 ||
                // CMD + Alt + C (Chrome)
                e.metaKey == true && e.altKey == true && e.keyCode == 67 ||
                // CMD + Shift + C (Chrome)
                e.metaKey == true && e.shiftKey == true && e.keyCode == 67 ||
                // Ctrl + Shift + I (Chrome, Firefox, Safari, Edge)
                e.ctrlKey == true && e.shiftKey == true && e.keyCode == 73 ||
                // Ctrl + Shift + J (Chrome, Edge)
                e.ctrlKey == true && e.shiftKey == true && e.keyCode == 74 ||
                // Ctrl + Shift + C (Chrome, Edge)
                e.ctrlKey == true && e.shiftKey == true && e.keyCode == 67 ||
                // F12 (Chome, Firefox, Edge)
                e.keyCode == 123 ||
                // CMD + Alt + U, Ctrl + U (View source: Chrome, Firefox, Safari, Edge)
                e.metaKey == true && e.altKey == true && e.keyCode == 85 ||
                e.ctrlKey == true && e.keyCode == 85
                ){
                    devToolsOpened(e);
            }
        });
      };
    
    
    }
    @HostListener('document:fullscreenchange', ['$event'])
    @HostListener('document:webkitfullscreenchange', ['$event'])
    @HostListener('document:mozfullscreenchange', ['$event'])
    @HostListener('document:MSFullscreenChange', ['$event'])

    ngOnInit() {
        this.authService.getUser()
        .subscribe((user: User) => {
            this.currentUser = user;
        });
    }
}

<div fxLayout="column" fxLayoutAlign="center center" class="reqistration-error h100 w100">

    <div class="w90 ml16" *ngIf='data.action == "isEmailExist"'>

        <h1 class="fw600">
            The email account you are using is already registered with us.
        </h1>

        <p class="fs16 lh30 mt24 fw100">
            We are reviewing your application and will get back to you as soon as possible. <br>
        </p>

        <p class="fs16 lh30 fw100">
            Please email us at <b class="fw600 blueColor">admin@brake.org.au</b> if you wish to modify your application
            or ask for any
            further information about the BRAKE Driver Awareness courses.
        </p>

        <p class="fs16 lh30 mt24 fw100">
            Kind regards,<br>
            Belinda Duncan,<br>
            BRAKE Administration<br>
        </p>
    </div>

    <div *ngIf='data.action == "isAccountActive"' fxLayout="column" fxLayoutAlign="center center"
        class="reqistration-error h100 w100">

        <div class="w90 ml16">

            <h1 class="fw600">
                The email account you are using is already registered with us.
            </h1>
            <p class="fs16 lh30 mt24 fw100">
                Please log into your account at <a href="www.bos.brake.org.au">www.bos.brake.org.au</a>. If you have
                forgotten your password, you can recover it there.
            </p>

            <p class="fs16 lh30 fw100">
                Please email us at <b class="fw600 blueColor">admin@brake.org.au</b> if you wish to ask for any further
                assistance or more
                information about the BRAKE Driver Awareness courses.
            </p>

            <p class="fs16 lh30 fw100 mt24">
                Kind regards,<br>
                Belinda Duncan,<br>
                BRAKE Administration<br>
            </p>
        </div>
    </div>

    <div *ngIf='data.action == "showTextAfterSubmit"' fxLayout="column" fxLayoutAlign="center center"
        class="reqistration-error h100 w100">

        <div class="w90 ml16">
            <h1>
                Hi {{data.application?.firstName}} {{data.application?.lastName}},
            </h1>

            <p class="fs18 fw100">
                Your application has been submitted to the BRAKE team.
                You will receive a confirmation email from BRAKE shortly.
            </p>

            <p class="fs16 lh30 mt24 fw600">
                YOUR DETAILS
            </p>

            <div class="fs14 lh30">
                <div class="mt14">
                    <span fxFlex="10%" class="fw600">
                        Name:
                    </span>
                    <span class="fw100">
                        {{data.application?.firstName}} {{data.application?.lastName}}
                    </span>
                </div>

                <div class="mt14">
                    <span fxFlex="10%" class="fw600">
                        Email:
                    </span>
                    <span class="fw100">
                        {{data.application?.email}}
                    </span>
                </div>

                <div class="mt14">
                    <span fxFlex="10%" class="fw600">
                        School:
                    </span>
                    <span class="fw100">
                        {{data.applicationSchoolName}}
                    </span>
                </div>
            </div>

            <p class="fs14 lh30 fw100 mt24">
                Your application will be processed shortly and you will be sent your account details<br>
                Thank you,<br>
                Belinda Duncan,<br>
                BRAKE Administration<br>
            </p>
        </div>
    </div>

    <div class="w100 mt32" fxLayoutAlign="center center">
        <button (click)='closePopup()' class="btn applyBtn" fxLayoutAlign="center center"
            mat-stroked-button>
            CLOSE
        </button>
    </div>
</div>
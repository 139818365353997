<div class="forgot-password-component h100">

    <div fxLayout="column" fxLayoutAlign="center center" class="h100">

        <div>
            <img src="../../../assets/img/logo.png" alt="">
        </div>

        <div *ngIf='!loading'>

            <form [formGroup]="updatePasswordForm" mat-dialog-content class="loginBox mt40" fxLayout="column"
                fxLayoutAlign="center center">

                <mat-form-field appearance="fill">
                    <mat-label>New password</mat-label>
                    <input autocomplete="off" formControlName="password" matInput [type]="hide ? 'password' : 'text'">

                    <mat-error *ngIf="password?.invalid && (password?.dirty || password?.touched)"
                        class="helper-text red-text">
                        <span *ngIf="password?.errors?.minlength">
                            Password must be at least 6 characters long.
                        </span>
                    </mat-error>

                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'hideRepeat password'"
                        [attr.aria-pressed]="hideRepeat">
                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                </mat-form-field>

                <mat-form-field appearance="fill" class="mt25">
                    <mat-label>Enter repeat your password</mat-label>
                    <input autocomplete="off" formControlName="repeatPassword" matInput
                        [type]="hideRepeat ? 'password' : 'text'">

                    <mat-error *ngIf="repeatPassword?.invalid && (repeatPassword?.dirty || repeatPassword?.touched)"
                        class="helper-text red-text">
                        <span *ngIf="repeatPassword?.errors?.minlength">
                            Password must be at least 6 characters long.
                        </span>
                    </mat-error>

                    <button mat-icon-button matSuffix (click)="hideRepeat = !hideRepeat"
                        [attr.aria-label]="'hideRepeat password'" [attr.aria-pressed]="hideRepeat">
                        <mat-icon>{{hideRepeat ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                </mat-form-field>

                <div fxLayoutAlign="center center" class="w100 mt32">
                    <button mat-flat-button class="loginBtn" [disabled]="!updatePasswordForm.valid"
                        (click)="updatePass(updatePasswordForm.value)" mat-stroked-button>
                        UPDATE
                    </button>
                </div>

                <div class="greenButton mt14 cursor" [routerLink]="['/sign-in']">
                    Sign In
                </div>
            </form>
        </div>

        <div *ngIf='loading' fxLayoutAlign="center " class="w100 mt40 spinner">
            <mat-spinner></mat-spinner>
        </div>

    </div>
</div>
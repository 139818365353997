import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Class } from '../shared/models/class/class';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Notes } from '../shared/models/class/notes';
import { AssignmentOfTeacherToClass } from '../shared/models/class/assignmentOfTeacherToClass';
import { AssignmentOfStudentsToClass } from '../shared/models/class/assignmentOfStudentsToClass';
import { Student } from '../shared/models/student/student';
import { AssignmentOfClassesToStudent } from '../shared/models/class/assignmentOfClassesToStudent';
import { ExcelStudents } from '../shared/models/student/importExcelStudent';
import { StudentModule } from '../shared/models/class/student-module';
import { HttpInternalService } from './http-internal.service';
import { NewClass } from '../shared/models/class/newClass';
import { FilterClass } from '../shared/models/class/filterClass';
import { UpdateStatusForQuizzClass } from '../shared/models/class/updateStatusForQuizzClass';
import { Success } from '../shared/models/success/success';

@Injectable({
    providedIn: 'root',
})
export class ClassService {
    public endpoint: string = `${environment.hostUrl}/api/classes`;

    constructor(
        private httpClient: HttpClient,
        private httpInternal: HttpInternalService
    ) {}

    getClasses(): Observable<{ data: Class[] }> {
        return this.httpClient
            .get<{ data: Class[] }>(this.endpoint)
            .pipe(
                catchError(this.handleError<{ data: Class[] }>({ data: [] }))
            );
    }

    getProgramSpecificClasses(
        programId: number,
        isAll: boolean
    ): Observable<{ data: Class[] }> {
        return this.httpClient
            .get<{ data: Class[] }>(
                this.endpoint +
                    `/programClasses?programId=${programId}&isAll=${isAll}`
            )
            .pipe(
                catchError(this.handleError<{ data: Class[] }>({ data: [] }))
            );
    }

    ClassFilter(classFilter: FilterClass): Observable<FilterClass[]> {
        let url = `${this.endpoint}/ClassFilter?`;
        return this.httpClient
            .get<FilterClass[]>(url, {
                params: {
                    classStatusId: classFilter.classStatusId,
                    isActive: classFilter.isActive,
                    teacherId: classFilter.teacherId,
                    startDate: classFilter.startDate,
                    endDate: classFilter.endDate,
                },
            })
            .pipe(catchError(this.handleError<FilterClass[]>([])));
    }

    checkModulesStatusInAllClasses(): Observable<Success> {
        return this.httpClient
            .post<Success>(
                `${this.endpoint}/checkModulesStatusInAllClasses`,
                null
            )
            .pipe(catchError(this.handleError<Success>()));
    }

    finishClass(classId: number): Observable<Success> {
        return this.httpClient
            .post<Success>(
                `${this.endpoint}/FinishClas?classId=${classId}`,
                null
            )
            .pipe(catchError(this.handleError<Success>()));
    }

    getNotes(): Observable<Notes[]> {
        return this.httpClient
            .get<Notes[]>(`${environment.hostUrl}/api/classes/notes`)
            .pipe(catchError(this.handleError<Notes[]>([])));
    }

    getNotesById(): Observable<Notes[]> {
        return this.httpClient
            .get<Notes[]>(`${environment.hostUrl}/api/classes/notes/1`)
            .pipe(catchError(this.handleError<Notes[]>([])));
    }

    getClass(id: number | string): Observable<Class> {
        const url = `${this.endpoint}/${id}`;
        return this.httpClient
            .get<Class>(url)
            .pipe(catchError(this.handleError<Class>()));
    }

    GetClassesBySchoolAdmin(
        id: number | string,
        programId: number = null,
        isAll: boolean = null
    ): Observable<Class[]> {
        const url = programId
            ? `${this.endpoint}/schoolAdmin/${id}?programId=${programId}&isShowAll=${isAll}`
            : `${this.endpoint}/schoolAdmin/${id}`;
        return this.httpClient
            .get<Class[]>(url)
            .pipe(catchError(this.handleError<Class[]>()));
    }

    GetClassByStudentLui(lui: string): Observable<Class[]> {
        const url = `${this.endpoint}/lui/${lui}`;
        return this.httpClient
            .get<Class[]>(url)
            .pipe(catchError(this.handleError<Class[]>()));
    }

    GetClassByStudentId(id: number | string): Observable<Class> {
        const url = `${this.endpoint}/student/${id}`;
        return this.httpClient
            .get<Class>(url)
            .pipe(catchError(this.handleError<Class>()));
    }

    getClassesAssignedToTeacher(
        teacherId: number,
        programId: number | null = null,
        isAll: boolean | null = null
    ): Observable<Class[]> {
        const url =
            programId && isAll
                ? `${this.endpoint}/teacher/${teacherId}?programId=${programId}&isShowAll=${isAll}`
                : `${this.endpoint}/teacher/${teacherId}`;

        return this.httpClient
            .get<Class[]>(url)
            .pipe(catchError(this.handleError<Class[]>()));
    }

    getClassesWithoutTeacher(): Observable<Class[]> {
        const url = `${this.endpoint}/withoutTeacher`;
        return this.httpClient
            .get<Class[]>(url)
            .pipe(catchError(this.handleError<Class[]>()));
    }

    getStudentsByTeacherId(
        teacherId: number,
        programId?: number,
        isAll?: boolean
    ): Observable<Student[]> {
        const url = `${this.endpoint}/teacher/${teacherId}/students?programId=${programId}&isShowAll=${isAll}`;
        return this.httpClient
            .get<Student[]>(url)
            .pipe(catchError(this.handleError<Student[]>()));
    }

    assignTeacherToClass(
        assignmentOfTeacherToClassDto: AssignmentOfTeacherToClass
    ): Observable<Class> {
        const url = `${this.endpoint}/assignTeacher`;
        return this.httpClient
            .put<Class>(url, assignmentOfTeacherToClassDto)
            .pipe(catchError(this.handleError<Class>()));
    }

    assignStudentsToClass(
        assignmentOfStudentsToClassDto: AssignmentOfStudentsToClass
    ): Observable<boolean> {
        const url = `${this.endpoint}/assignStudents`;
        return this.httpClient
            .put<boolean>(url, assignmentOfStudentsToClassDto)
            .pipe(catchError(this.handleError<boolean>()));
    }

    assignStudentToClass(
        assignmentOfClassesToStudentDto: AssignmentOfClassesToStudent
    ): Observable<boolean> {
        const url = `${this.endpoint}/assignStudent`;
        return this.httpClient
            .put<boolean>(url, assignmentOfClassesToStudentDto)
            .pipe(catchError(this.handleError<boolean>()));
    }

    saveStudents(students: ExcelStudents[]): Observable<any> {
        return this.httpClient
            .post<ExcelStudents[]>(
                `${this.endpoint}/assignImportedStudents`,
                students
            )
            .pipe(catchError(this.handleError<any>([])));
    }

    getStudentModules(studentId: number): Observable<StudentModule[]> {
        return this.httpClient
            .get<StudentModule[]>(
                `${this.endpoint}/students/${studentId}/modules`
            )
            .pipe(catchError(this.handleError<StudentModule[]>([])));
    }

    createNewClass(classes: NewClass): Observable<number> {
        const url = `${this.endpoint}`;
        return this.httpInternal
            .postRequest<number>(url, classes)
            .pipe(catchError(this.handleError<number>()));
    }

    GetClassesBySchool(id: number | string): Observable<Class[]> {
        const url = `${this.endpoint}/GetClassesBySchool?schoolId=${id}`;
        return this.httpClient
            .get<Class[]>(url)
            .pipe(catchError(this.handleError<Class[]>()));
    }

    editClassInformation(classes: NewClass): Observable<boolean> {
        const url = `${this.endpoint}/updateClass`;
        return this.httpClient
            .put<boolean>(url, classes)
            .pipe(catchError(this.handleError<boolean>()));
    }

    updateEndDate(classId: number): Observable<boolean> {
        const url = `${this.endpoint}/updateEndDate/${classId}`;
        return this.httpClient
            .put<boolean>(url, null)
            .pipe(catchError(this.handleError<boolean>()));
    }

    activateClass(classId: number): Observable<boolean> {
        const url = `${this.endpoint}/ActivateClass?classId=${classId}`;
        return this.httpClient
            .put<boolean>(url, null)
            .pipe(catchError(this.handleError<boolean>()));
    }

    deleteClass(classId: number) {
        const url = `${this.endpoint}/deleteClass`;
        return this.httpInternal.deleteFullRequest(`${url}?id=${classId}`);
    }

    updateQuizzStatusForClass(
        updateStatus: UpdateStatusForQuizzClass
    ): Observable<boolean> {
        const url = `${this.endpoint}/quizzClass/updateStatus`;
        return this.httpClient
            .put<boolean>(url, updateStatus)
            .pipe(catchError(this.handleError<boolean>()));
    }

    sendRequestAboutFreeClass(classId: number | string): Observable<Success> {
        return this.httpClient
            .post<Success>(
                `${this.endpoint}/free/sendRequest?classId=${classId}`,
                classId
            )
            .pipe(catchError(this.handleError<Success>()));
    }

    sendAboutActiveClass(classId: number | string): Observable<Success> {
        return this.httpClient
            .post<Success>(
                `${this.endpoint}/free/sendRequest?classId=${classId}`,
                classId
            )
            .pipe(catchError(this.handleError<Success>()));
    }

    activateClassFree(
        activationCode: string,
        classId: number
    ): Observable<Success> {
        const url = `${this.endpoint}/free/activateClass?activationCode=${activationCode}&classId=${classId}`;
        return this.httpClient
            .put<Success>(url, activationCode)
            .pipe(catchError(this.handleError<Success>()));
    }

    declineClassFree(classId: number | string): Observable<Success> {
        return this.httpClient
            .post<Success>(
                `${this.endpoint}/free/declineRequest?classId=${classId}`,
                classId
            )
            .pipe(catchError(this.handleError<Success>()));
    }

    ChangeIsSendRequestStatus(
        classId: number,
        status: boolean
    ): Observable<boolean> {
        const url = `${this.endpoint}/ChangeIsSendRequestStatus?classId=${classId}&status=${status}`;
        return this.httpClient
            .put<boolean>(url, classId)
            .pipe(catchError(this.handleError<boolean>()));
    }

    private handleError<T>(result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            return of(result as T);
        };
    }
}

import { Injectable } from '@angular/core';
import { HttpInternalService } from './http-internal.service';
import { AccessTokenDto } from '../shared/models/token/access-token-dto';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { UserRegisterDto } from '../shared/models/auth/user-register-dto';
import { AuthUser } from '../shared/models/auth/auth-user';
import { UserLoginDto } from '../shared/models/auth/user-login-dto';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { User } from '../shared/models/user';
import { UserService } from './user.service';
import { EventService } from './event.service';
import { Router } from '@angular/router';
import { SendEmail } from '../shared/models/password/sendEmail';
import { environment } from 'src/environments/environment';
import { ResetPassword } from '../shared/models/password/resetPassword';
import { ConfirmEmail } from '../shared/models/password/confirmEmail';
import { ForgotPass } from '../shared/models/password/forgotPass';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    public routePrefix = '/api';
    public endpoint: string = `${environment.hostUrl}/api/auth`;
    private user: User;
    public userSubject: BehaviorSubject<any>;
    loading!: boolean;

    constructor(
        private httpService: HttpInternalService,
        private userService: UserService,
        private httpClient: HttpClient,
        private eventService: EventService,
        private router: Router) {
        this.userSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('user') || 'null'));
    }

    public getUser() {
        if (this.user) {
            return of(this.user);
        }
        else if (localStorage.getItem('accessToken')) {
            return this.userService.getUserFromToken()
                .pipe(
                    map((resp) => {
                        this.user = resp.body;
                        this.eventService.userChanged(this.user);
                        return this.user;
                    }
                    ));
        }
        return of();
    }

    public setUser(user: User) {
        this.user = user;
        this.eventService.userChanged(user);
    }

    public register(user: UserRegisterDto) {
        return this._handleAuthResponse(this.httpService.postFullRequest<AuthUser>(`${this.routePrefix}/register`, user));
    }

    public login(user: UserLoginDto) {
        return this._handleAuthResponse(this.httpService.postFullRequest<AuthUser>(`${this.routePrefix}/auth/login`, user));
    }

    public logout() {
        this.revokeRefreshToken()
            .subscribe(() => {
                this.removeTokensFromStorage();
                this.user = undefined;
                this.eventService.userChanged(undefined);
                this.loading = false;
                this.router.navigate(['/sign-in'])
            });
    }

    public areTokensExist() {
        return localStorage.getItem('accessToken') && localStorage.getItem('refreshToken');
    }

    public revokeRefreshToken() {
        return this.httpService.postFullRequest<AccessTokenDto>(`${this.routePrefix}/token/revoke`, {
            refreshToken: localStorage.getItem('refreshToken')
        });
    }

    public removeTokensFromStorage() {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('role');
    }

    public refreshTokens() {
        return this.httpService
            .postFullRequest<AccessTokenDto>(`${this.routePrefix}/token/refresh`, {
                accessToken: JSON.parse(localStorage.getItem('accessToken')),
                refreshToken: JSON.parse(localStorage.getItem('refreshToken'))
            })
            .pipe(
                map((resp) => {
                    this._setTokens(resp.body);
                    return resp.body;
                })
            );
    }

    private _handleAuthResponse(observable: Observable<HttpResponse<AuthUser>>) {
        return observable.pipe(
            map((resp) => {
                this._setTokens(resp.body.accessToken);
                this.user = resp.body.user;
                this.eventService.userChanged(resp.body.user);
                return resp.body.user;
            })
        );
    }

    sendEmail(email: SendEmail): Observable<ForgotPass> {
        return this.httpClient.post<ForgotPass>(`${this.endpoint}/ForgotPassword?email=${email.email}`, null)
            .pipe(
                catchError(this.handleError<ForgotPass>())
            );
    }

    confirmEmail(token: number | string): Observable<ConfirmEmail> {
        const url = `${this.endpoint}/ConfirmEmail?token=${token}`;
        return this.httpClient.get<ConfirmEmail>(url)
            .pipe(
                catchError(this.handleError<ConfirmEmail>())
            );
    }

    resetPassword(resetPassword: ResetPassword): Observable<any> {
        const url = `${this.endpoint}/ResetPassword?id=${resetPassword.id}&password=${resetPassword.password}`;
        return this.httpClient.put<any>(url, resetPassword)
            .pipe(
                catchError(this.handleError<any>())
            );
    }

    private _setTokens(tokens: AccessTokenDto) {
        if (tokens && tokens.accessToken && tokens.refreshToken) {
            localStorage.setItem('accessToken', JSON.stringify(tokens.accessToken.token));
            localStorage.setItem('refreshToken', JSON.stringify(tokens.refreshToken));
            this.getUser();
        }
    }

    private handleError<T>(result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            return of(result as T);
        }
    }
}

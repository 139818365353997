import { Inject } from '@angular/core';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SchoolAdminService } from 'src/app/services/school-admin.service';

@Component({
  selector: 'app-school-admin-instraction',
  templateUrl: './school-admin-instraction.component.html',
  styleUrls: ['./school-admin-instraction.component.css']
})
export class SchoolAdminInstractionComponent implements OnInit {

  showBtnOk: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<SchoolAdminInstractionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private schoolAdminService: SchoolAdminService
  ) { }

  ngOnInit(): void {
    setTimeout(() => this.showBtnOk = true, 4000);
  }

  clickClose() {
    this.schoolAdminService.ChangeFirstTimeSatus(this.data.schoolAdminId)
      .subscribe((res) => {
        console.log(res);
      }, (err) => {
        console.log(err);
      })
    this.dialogRef.close();
  }

}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CreatedSchoolAdmin } from '../shared/models/schoolAdmin/createdSchoolAdmin';
import { NewSchoolAdmin } from '../shared/models/schoolAdmin/newSchoolAdmin';
import { SchoolAdmin } from '../shared/models/schoolAdmin/schoolAdmin';
import { Success } from '../shared/models/success/success';
import { HttpInternalService } from './http-internal.service';

@Injectable({
  providedIn: 'root'
})

export class SchoolAdminService {
  private endpoint = `${environment.hostUrl}/api/schoolAdmins`;

  constructor(
    private httpClient: HttpClient,
    private httpInternal: HttpInternalService
  ) { }

  createNewSchoolAdmin(schoolAdmin: NewSchoolAdmin): Observable<CreatedSchoolAdmin> {
    return this.httpInternal.postRequest<CreatedSchoolAdmin>(this.endpoint, schoolAdmin)
      .pipe(
        catchError(this.handleError<CreatedSchoolAdmin>())
      );
  }

  editSchoolAdminInformation(schoolAdmin: NewSchoolAdmin): Observable<Success> {
    const url = `${this.endpoint}/updateSchoolAdmin`;
    return this.httpClient.put<Success>(url, schoolAdmin)
      .pipe(
        catchError(this.handleError<Success>())
      );
  }

  getSchoolAdminById(id: number | string): Observable<SchoolAdmin> {
    const url = `${this.endpoint}/${id}`;
    return this.httpClient.get<SchoolAdmin>(url)
      .pipe(
        catchError(this.handleError<SchoolAdmin>())
      );
  }

  deleteSchoolAdmin(schoolAdminId: number) {
    const url = `${this.endpoint}/deleteSchoolAdmin`;
    return this.httpInternal.deleteFullRequest(`${url}?id=${schoolAdminId}`);
  }

  ChangeFirstTimeSatus(id: number): Observable<Success> {
    const url = `${this.endpoint}/ChangeFirtTimeStatus?id=${id}`;
    return this.httpClient.put<Success>(url, id)
      .pipe(
        catchError(this.handleError<Success>())
      );
  }

  private handleError<T>(result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/auth.service';
import { Location } from '@angular/common';
import { ResetPassword } from 'src/app/shared/models/password/resetPassword';
import { NewPassword } from 'src/app/shared/models/password/newPassword';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import * as CryptoJS from 'crypto-js';

export enum Role {
  Admin = 1,
  Teacher = 2,
  Student = 3,
  SchoolAdmin = 4,
  SuperAdmin = 5,
}

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.css']
})
export class UpdatePasswordComponent implements OnInit {

  hide: boolean = true;
  hideRepeat: boolean = true;
  token!: string;
  duration: number = 5000;

  updatePasswordForm!: FormGroup;

  loading: boolean = false;

  constructor(
    private authService: AuthenticationService,
    private snackBar: MatSnackBar,
    private router: Router,
    private location: Location,
    private snackBarService: SnackBarService,
  ) { }

  ngOnInit() {
    const string = this.location.path();
    this.token = string.split('?token=').pop();
    this.updatePasswordForm = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      repeatPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
    });
  }

  get password() { return this.updatePasswordForm.get('password'); }
  get repeatPassword() { return this.updatePasswordForm.get('repeatPassword'); }

  updatePass(passwordForm: NewPassword) {
    this.loading = true;
    if (passwordForm.password == passwordForm.repeatPassword) {
      this.authService.confirmEmail(this.token)
        .subscribe((res) => {
          if (res.userId) {
            const resetPass: ResetPassword = {
              id: Number(res.userId),
              password: passwordForm.password
            }
            this.authService.resetPassword(resetPass)
              .subscribe((res) => {
                if (res.isSuccess) {
                  var key = CryptoJS.MD5(res.salt);
                  var message = res.password
                  const decrypted = CryptoJS.TripleDES.decrypt(
                    message,
                    key,
                    {
                      mode: CryptoJS.mode.ECB,
                      padding: CryptoJS.pad.Pkcs7
                    }
                  ).toString(CryptoJS.enc.Utf8);
                  this.signIn(res.userName, decrypted)
                }
                this.snackBar.open(res.responseMessage, '', {
                  duration: this.duration
                });
              }, (err) => {
                console.log(err);
              })
          } else {
            this.snackBar.open(res.responseMessage, '', {
              duration: this.duration
            });
            this.loading = false
          }
        }, (err) => console.log(err)
        )
    } else {
      this.snackBar.open('Error! The passwords are different!', '', {
        duration: this.duration
      });
      this.loading = false
    }
  }

  signIn(username: string, password: string) {
    this.loading = true;
    this.authService
      .login({ username: username, password: password })
      .subscribe(
        (response) => {
          if (response.roleId == Role.Admin) {
            this.router.navigate(['/admin', 'my-record'])
          }
          if (response.roleId == Role.Teacher) {
            this.router.navigate(['/teacher', 'my-record'])
          }
          if (response.roleId == Role.Student) {
            this.router.navigate(['/student', 'quizzes'])
          }
          if (response.roleId == Role.SchoolAdmin) {
            this.router.navigate(['/school-admin', 'my-record'])
          }
          if (response.roleId == Role.SuperAdmin) {
            this.router.navigate(['/super-admin', 'my-record'])
          }
        },
        (error) => this.snackBarService.showErrorMessage(error)
      );
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Quizz } from '../shared/models/quiZAdmin/quizz';
import { AssigneStudentsToQuiz } from '../shared/models/quizStudent/assigneStudentsToQuiz';
import { AssigneStudentsToQuizzes } from '../shared/models/quizStudent/assigneStudentsToQuizzes';
import { AssigneOneStudentToQuiz } from '../shared/models/quizStudent/assigneOneStudentToQuiz';
import { ChangeQuizStatus } from '../shared/models/quizStudent/ChangeQuiZStatus';
import { FinishedQuizStatus } from '../shared/models/quizStudent/finishedQuizStatus';
import { Success } from '../shared/models/success/success';
import { HttpInternalService } from './http-internal.service';
import { QuizStudentActionLog } from '../shared/models/quizStudent/quizStudentActionLog';

@Injectable({
  providedIn: 'root'
})

export class QuizSrudentsService {

  private endpoint = `${environment.hostUrl}/api/QuizStudent`;

  constructor(
    private httpClient: HttpClient,
  ) { }

  assigneStudentsToQuizzes(assigneStudentsToQuizzes: AssigneStudentsToQuizzes): Observable<Success> {
    const url = `${this.endpoint}/AssigneStudentsToQuizzesByModuleId?moduleId=${assigneStudentsToQuizzes.moduleId}&classId=${assigneStudentsToQuizzes.classId}`
    return this.httpClient.post<Success>(url, assigneStudentsToQuizzes)
      .pipe(
        catchError(this.handleError<Success>())
      );
  }

  AddQuizStudentActionLog(quizStudentActionLog: QuizStudentActionLog): Observable<Success> {
    const url = `${this.endpoint}/AddQuizStudentActionLog?studentId=${quizStudentActionLog.studentId}&quizId=${quizStudentActionLog.quizId}&status=${quizStudentActionLog.status}`
    return this.httpClient.post<Success>(url, quizStudentActionLog)
      .pipe(
        catchError(this.handleError<Success>())
      );
  }

  AssingeStudentsUponClassCreated(classId: number): Observable<Success> {
    const url = `${this.endpoint}/AssingeStudentsUponClassCreated?classId=${classId}`
    return this.httpClient.post<Success>(url, classId)
      .pipe(
        catchError(this.handleError<Success>())
      );
  }

  AssigneStudentsToQuizByClassId(assigneStudentsToQuiz: AssigneStudentsToQuiz): Observable<Success> {
    const url = `${this.endpoint}/AssigneStudentsToQuizByClassId?quizId=${assigneStudentsToQuiz.quizId}&classId=${assigneStudentsToQuiz.classId}`
    return this.httpClient.post<Success>(url, assigneStudentsToQuiz)
      .pipe(
        catchError(this.handleError<Success>())
      );
  }

  AssignOneStudentToQuiz(assigneOneStudentToQuiz: AssigneOneStudentToQuiz): Observable<any> {
    const url = `${this.endpoint}/AssingeStudentToQuiz?quizId=${assigneOneStudentToQuiz.quizId}&studentId=${assigneOneStudentToQuiz.studentId}`
    return this.httpClient.post<any>(url, assigneOneStudentToQuiz)
      .pipe(
        catchError(this.handleError<any>())
      );
  }

  GetQuizMaxPoints(id: number | string): Observable<number> {
    const url = `${this.endpoint}/GetQuizMaxPoints?quizId=${id}`;
    return this.httpClient.get<number>(url)
      .pipe(
        catchError(this.handleError<number>())
      );
  }

  GetQuizzesByStudentId(id: number | string): Observable<Quizz[]> {
    const url = `${this.endpoint}/GetQuizzesByStudentId?studentId=${id}`;
    return this.httpClient.get<Quizz[]>(url)
      .pipe(
        catchError(this.handleError<Quizz[]>([]))
      );
  }

  GetQuizResult(quizId: number | string, studentId: number | string): Observable<any> {
    const url = `${this.endpoint}/GetQuizResult?quizId=${quizId}&studentId=${studentId}`;
    return this.httpClient.get<any>(url)
      .pipe(
        catchError(this.handleError<any>())
      );
  }

  GetQuizById(quizId: number | string, studentId: number | string): Observable<Quizz> {
    const url = `${this.endpoint}/GetQuizById?quizId=${quizId}&studentId=${studentId}`;
    return this.httpClient.get<Quizz>(url)
      .pipe(
        catchError(this.handleError<Quizz>())
      );
  }

  ChangeIsPausedStatus(changeIsPausedStatus: ChangeQuizStatus): Observable<Success> {
    const url = `${this.endpoint}/ChangeIsPausedStatus?studentId=${changeIsPausedStatus.studentId}&quizId=${changeIsPausedStatus.quizId}&status=${changeIsPausedStatus.status}`;
    return this.httpClient.put<Success>(url, changeIsPausedStatus)
      .pipe(
        catchError(this.handleError<Success>())
      );
  }

  ChangeIsStartedStatus(changeIsPausedStatus: ChangeQuizStatus): Observable<Success> {
    const url = `${this.endpoint}/ChangeIsStartedStatus?studentId=${changeIsPausedStatus.studentId}&quizId=${changeIsPausedStatus.quizId}&status=${changeIsPausedStatus.status}`;
    return this.httpClient.put<Success>(url, changeIsPausedStatus)
      .pipe(
        catchError(this.handleError<Success>())
      );
  }

  ChangeIsOpenStatus(changeIsPausedStatus: ChangeQuizStatus): Observable<Success> {
    const url = `${this.endpoint}/ChangeIsOpenStatus?studentId=${changeIsPausedStatus.studentId}&quizId=${changeIsPausedStatus.quizId}&status=${changeIsPausedStatus.status}`;
    return this.httpClient.put<Success>(url, changeIsPausedStatus)
      .pipe(
        catchError(this.handleError<Success>())
      );
  }

  ChangeRetakequizStatus(changeIsPausedStatus: ChangeQuizStatus): Observable<Success> {
    const url = `${this.endpoint}/ChangeRetakequizStatus?studentId=${changeIsPausedStatus.studentId}&quizId=${changeIsPausedStatus.quizId}&status=${changeIsPausedStatus.status}`;
    return this.httpClient.put<Success>(url, changeIsPausedStatus)
      .pipe(
        catchError(this.handleError<Success>())
      );
  }

  UpdateIsFinishedQuizStatusForStudent(finishedQuizStatus: FinishedQuizStatus): Observable<Success> {
    const url = `${this.endpoint}/UpdateIsFinishedQuizStatusForStudent?studentId=${finishedQuizStatus.studentId}&quizId=${finishedQuizStatus.quizId}`;
    return this.httpClient.put<Success>(url, finishedQuizStatus)
      .pipe(
        catchError(this.handleError<Success>())
      );
  }

  CheckQuestionsResults(assigneStudentsToQuizzes: any): Observable<any> {
    const url = `${this.endpoint}/CheckQuestionsResults`
    return this.httpClient.post<any>(url, assigneStudentsToQuizzes)
      .pipe(
        catchError(this.handleError<any>())
      );
  }

  CheckQuestionResult(questionId: number, answers: any[]): Observable<any> {
    const url = `${this.endpoint}/CheckQuestionResult?questionId=${questionId}`
    return this.httpClient.post<any>(url, answers)
      .pipe(
        catchError(this.handleError<any>())
      );
  }

  StartQuiz(assigneStudentsToQuizzes: any): Observable<Success> {
    const url = `${this.endpoint}/StartQuiz`
    return this.httpClient.post<Success>(url, assigneStudentsToQuizzes)
      .pipe(
        catchError(this.handleError<Success>())
      );
  }

  RetakeQuiz(assigneStudentsToQuizzes: any): Observable<Success> {
    const url = `${this.endpoint}/RetakeQuiz`
    return this.httpClient.post<Success>(url, assigneStudentsToQuizzes)
      .pipe(
        catchError(this.handleError<Success>())
      );
  }

  ContinueQuiz(assigneStudentsToQuizzes: any): Observable<Success> {
    const url = `${this.endpoint}/ContinueQuiz`;
    return this.httpClient.put<Success>(url, assigneStudentsToQuizzes)
      .pipe(
        catchError(this.handleError<Success>())
      );
  }

  UnassignQuizzesByClassId(classId: number): Observable<Success> {
    const url = `${this.endpoint}/UnassignQuizzesByClassId?classId=${classId}`
    return this.httpClient.post<Success>(url, classId)
      .pipe(
        catchError(this.handleError<Success>())
      );
  }

  private handleError<T>(result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T && error);
    }
  }
}

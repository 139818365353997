<div fxLayout="column" fxLayoutAlign="center center" class="confirmation-popup-container h100 w">
	<div class="center fs20 fw600 mt40">
		{{ data.head }}
	</div>

	<div *ngIf="data.question" class="center fs20 fw600 mt40">
		{{ data.question }}
	</div>

	<div fxLayout="row" fxLayoutAlign="space-around center" class="mt50 w100">
		<div>
			<button (click)="clickNo()" class="btn cancelBtn" fxLayoutAlign="center center" mat-stroked-button>NO</button>
		</div>

		<div>
			<button (click)="clickYes()" class="btn applyBtn" fxLayoutAlign="center center" mat-stroked-button>YES</button>
		</div>
	</div>
</div>

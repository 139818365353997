import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})

export class ForgotPasswordComponent implements OnInit {

  passwordForm!: FormGroup;

  loading: boolean = false;
  duration: number = 5000;

  constructor(
    private authService: AuthenticationService,
    private snackBar: MatSnackBar,
    private router: Router,
  ) { }

  ngOnInit() {
    this.passwordForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  get email() { return this.passwordForm.get('email'); }

  sendEmail(email: FormGroup) {
    if (email.valid) {
      this.loading = true;
      this.authService.sendEmail(email.value)
        .subscribe((res) => {
          if (res.isSuccess) {
            this.snackBar.open('Letter sent successfully! Check your email!', '', {
              duration: this.duration
            });
            this.loading = false;
            this.router.navigate(['sign-in']);
          } else {
            this.snackBar.open(res.responseMessage, '', {
              duration: this.duration
            });
            this.loading = false;
          }
        }, (err) => {
          console.log(err);
        })
    } else {
      if (email.dirty) {
        this.snackBar.open('Enter the correct email!', '', {
          duration: this.duration
        });
      } else {
        if (email.value.email == '') {
          this.snackBar.open('Enter email!', '', {
            duration: this.duration
          });
        }
      }
    }
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpInternalService } from '../services/http-internal.service';
import { NewAdmin } from '../shared/models/admin/NewAdmin';
import { EmailExist } from '../shared/models/email/emailExist';
import { Success } from '../shared/models/success/success';
import { User } from '../shared/models/user';

@Injectable({ providedIn: 'root' })
export class UserService {
    public routePrefix = '/api/users';
    public endpoint: string = `${environment.hostUrl}/api/users`;

    constructor(
        private httpService: HttpInternalService,
        private httpClient: HttpClient
    ) {}

    public getUserFromToken() {
        return this.httpService.getFullRequest<User>(
            `${this.routePrefix}/fromToken`
        );
    }

    public getUserById(id: number) {
        return this.httpService.getFullRequest<User>(
            `${this.routePrefix}/${id}`
        );
    }

    getAdmins(): Observable<User[]> {
        return this.httpClient
            .get<User[]>(`${this.endpoint}/admins`)
            .pipe(catchError(this.handleError<User[]>([])));
    }

    getAdminById(id: number | string): Observable<User> {
        const url = `${this.endpoint}/${id}`;
        return this.httpClient
            .get<User>(url)
            .pipe(catchError(this.handleError<User>()));
    }

    deleteAdmin(id: number) {
        const url = `${this.endpoint}/${id}`;
        return this.httpService.deleteFullRequest(url);
    }

    createNewAdmin(admin: NewAdmin): Observable<User> {
        const url = `${this.endpoint}/createAdmin`;
        return this.httpService
            .postRequest<User>(url, admin)
            .pipe(catchError(this.handleError<User>()));
    }

    createNewSuperAdmin(admin: NewAdmin): Observable<User> {
        const url = `${this.endpoint}/createSuperAdmin`;
        return this.httpService
            .postRequest<User>(url, admin)
            .pipe(catchError(this.handleError<User>()));
    }

    getSchoolAdmins(): Observable<User[]> {
        return this.httpClient
            .get<User[]>(`${this.endpoint}/schoolAdmins`)
            .pipe(catchError(this.handleError<User[]>([])));
    }

    emailExist(email: string): Observable<EmailExist> {
        return this.httpClient
            .get<EmailExist>(`${this.endpoint}/emailExist/${email}`)
            .pipe(catchError(this.handleError<EmailExist>()));
    }

    getSchoolAdminsInSchool(id: number | string): Observable<User[]> {
        return this.httpClient
            .get<User[]>(`${this.endpoint}/schoolAdminsInSchools/${id}`)
            .pipe(catchError(this.handleError<User[]>([])));
    }

    updateUser(user: User): Observable<Success> {
        const url = `${this.endpoint}/UpdateUserInformation`;
        return this.httpClient
            .put<Success>(url, user)
            .pipe(catchError(this.handleError<Success>()));
    }

    checkSchoolAdminPermissions(
        userId: number
    ): Observable<{ isSuccess: boolean }> {
        return this.httpClient
            .get<{ isSuccess: boolean }>(
                `${this.endpoint}/checkSchoolAdminOptionalModulePermissions?userId=${userId}`
            )
            .pipe(
                catchError(
                    this.handleError<{ isSuccess: boolean }>({
                        isSuccess: false,
                    })
                )
            );
    }

    private handleError<T>(result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            return of(result as T);
        };
    }
}

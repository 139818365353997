import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApplicationEmailConfirm } from '../shared/models/application/applicationEmailConfirm';
import { ActivateClass } from '../shared/models/email/activateClass';
import { AdminRegistration } from '../shared/models/email/adminRegistration';
import { ApproveInformation } from '../shared/models/email/approveInformation';
import { AssignQuiz } from '../shared/models/email/assignQuiz';
import { DeclineApplication } from '../shared/models/email/declineApplication';
import { EmailLog } from '../shared/models/email/emailLog';
import { FinishedQuiz } from '../shared/models/email/finishedQuiz';
import { ModuleCompletion } from '../shared/models/email/module-completion';
import { Notification } from '../shared/models/email/notification';
import { PurchaseInvoice } from '../shared/models/email/purchase-invoice';
import { SchoolAdminRegistration } from '../shared/models/email/schoolAdminRegistration';
import { StudentRegistration } from '../shared/models/email/student-registration';
import { StudentRegistrationWithoutClass } from '../shared/models/email/StudentRegistrationWithoutClass';
import { TeacherInvoiceForStudentPlaces } from '../shared/models/email/teacher-invoice-for-student-places';
import { TeacherRegistration } from '../shared/models/email/teacher-registration';
import { Success } from '../shared/models/success/success';
import { HttpInternalService } from './http-internal.service';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  public endpoint: string = `${environment.hostUrl}/api/emails`;

  constructor(
    private httpInternal: HttpInternalService,
    private httpClient: HttpClient
  ) { }

  getEmailSenderLogs(): Observable<EmailLog[]> {
    return this.httpClient.get<EmailLog[]>(`${this.endpoint}/GetEmailSenderLogs`)
      .pipe(
        catchError(this.handleError<EmailLog[]>([]))
      );
  }

  sendModuleCompletionEmails(moduleCompletion: ModuleCompletion): Observable<any> {
    return this.httpInternal.postRequest<any>(`${this.endpoint}/moduleCompletion`, moduleCompletion)
      .pipe(
        catchError(this.handleError<any>())
      );
  }

  sendTeacherRegistration(teacherRegistration: TeacherRegistration): Observable<Success> {
    return this.httpInternal.postRequest<Success>(`${this.endpoint}/teacherRegistration`, teacherRegistration)
      .pipe(
        catchError(this.handleError<Success>())
      );
  }

  sendAdminRegistration(adminRegistration: AdminRegistration): Observable<Success> {
    return this.httpInternal.postRequest<Success>(`${this.endpoint}/adminRegistration`, adminRegistration)
      .pipe(
        catchError(this.handleError<Success>())
      );
  }

  sendApplicationEmailConfirm(applicationEmailConfirm: ApplicationEmailConfirm): Observable<any> {
    return this.httpInternal.postRequest<any>(`${this.endpoint}/ConfirmApplication`, applicationEmailConfirm)
      .pipe(
        catchError(this.handleError<any>())
      );
  }

  sendApproveInformation(approveInformation: ApproveInformation): Observable<any> {
    return this.httpInternal.postRequest<any>(`${this.endpoint}/ApproveInformation`, approveInformation)
      .pipe(
        catchError(this.handleError<any>())
      );
  }

  SendFinishedQuiz(finishedQuiz: FinishedQuiz): Observable<any> {
    return this.httpInternal.postRequest<any>(`${this.endpoint}/SendFinishedQuiz`, finishedQuiz)
      .pipe(
        catchError(this.handleError<any>())
      );
  }

  sendDeclineApplication(declineApplication: DeclineApplication): Observable<any> {
    return this.httpInternal.postRequest<any>(`${this.endpoint}/DeclineApplication`, declineApplication)
      .pipe(
        catchError(this.handleError<any>())
      );
  }

  sendSchoolAdminRegistration(schoolAdminRegistration: SchoolAdminRegistration): Observable<Success> {
    return this.httpInternal.postRequest<Success>(`${this.endpoint}/schoolAdminRegistration`, schoolAdminRegistration)
      .pipe(
        catchError(this.handleError<Success>())
      );
  }

  SendClassActivateInfo(activateClass: ActivateClass): Observable<Success> {
    return this.httpInternal.postRequest<Success>(`${this.endpoint}/SendClassActivateInfo?classId=${activateClass.classId}`, activateClass)
      .pipe(
        catchError(this.handleError<Success>())
      );
  }

  sendStudentRegistration(studentRegistration: StudentRegistration | StudentRegistrationWithoutClass): Observable<Success> {
    return this.httpInternal.postRequest<Success>(`${this.endpoint}/studentRegistration`, studentRegistration)
      .pipe(
        catchError(this.handleError<Success>())
      );
  }

  sendTeacherInvoiceForStudentPlaces(teacherInvoice: TeacherInvoiceForStudentPlaces): Observable<any> {
    return this.httpInternal.postRequest<any>(`${this.endpoint}/teacherInvoiceForStudentPlaces`, teacherInvoice)
      .pipe(
        catchError(this.handleError<any>())
      );
  }

  sendPurchaseInvoice(purchaseInvoice: PurchaseInvoice): Observable<any> {
    return this.httpInternal.postRequest<any>(`${this.endpoint}/purchaseInvoice`, purchaseInvoice)
      .pipe(
        catchError(this.handleError<any>())
      );
  }

  SendTeacherApproveActivationClass(classId: any): Observable<any> {
    return this.httpInternal.postRequest<any>(`${this.endpoint}/SendTeacherApproveActivationClass`, classId)
      .pipe(
        catchError(this.handleError<any>())
      );
  }

  sendNotification(notification: Notification): Observable<Success> {
    return this.httpInternal.postRequest<Success>(`${this.endpoint}/Notification?eventType=${notification.eventType}`, notification)
      .pipe(
        catchError(this.handleError<Success>())
      );
  }

  SendAssigneQuizStudent(assignQuiz: AssignQuiz): Observable<Success> {
    return this.httpInternal.postRequest<Success>(`${this.endpoint}/SendAssigneQuizStudent?studentId=${assignQuiz.studentId}&quizId=${assignQuiz.quizId}`, assignQuiz)
      .pipe(
        catchError(this.handleError<Success>())
      );
  }

  private handleError<T>(result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    }
  }
}

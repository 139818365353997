import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-login-error',
  templateUrl: './login-error.component.html',
  styleUrls: ['./login-error.component.css']
})
export class LoginErrorComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<LoginErrorComponent>,
  ) { }

  ngOnInit(): void {

  }
  onClose(){
    this.dialogRef.close('close');
  }

}

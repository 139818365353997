import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { School } from '../shared/models/school/school';
import { UpdateStatusForQuizzSchool } from '../shared/models/school/updateStatusForQuizzSchool';
import { UpdateStatusForQuizzSchools } from '../shared/models/school/updateStatusForQuizzSchools';
import { HttpInternalService } from './http-internal.service';

export interface OutsideProgramSchool {
    id: number;
    name: string;
    address1: string;
    fax: string;
}

@Injectable({
    providedIn: 'root',
})
export class SchoolService {
    private endpoint = `${environment.hostUrl}/api/schools`;

    private headers = new HttpHeaders({
        'Content-type': 'application/json',
    });

    private _schoolList$ = new BehaviorSubject<School[]>([]);
    private _programSchoolList$ = new BehaviorSubject<{ data: School[] }>({
        data: [],
    });
    schoolList$ = this._schoolList$.asObservable();

    constructor(
        private httpClient: HttpClient,
        private httpInternal: HttpInternalService
    ) {}

    getSchools(): Observable<School[]> {
        return this.httpClient
            .get<School[]>(this.endpoint, { headers: this.headers })
            .pipe(
                tap((list) => this._schoolList$.next(list)),
                catchError(this.handleError<School[]>([]))
            );
    }

    getProgramRelatedSchools(
        programId: number,
        isAll: boolean
    ): Observable<{ data: School[] }> {
        return this.httpClient
            .get<{ data: School[] }>(
                this.endpoint +
                    `/programSchools?programId=${programId}&isAll=${isAll}`,
                {
                    headers: this.headers,
                }
            )
            .pipe(
                tap((list) => this._programSchoolList$.next(list)),
                catchError(this.handleError<{ data: School[] }>({ data: [] }))
            );
    }

    getSchoolById(id: number | string): Observable<School> {
        const url = `${this.endpoint}/${id}`;
        return this.httpClient
            .get<School>(url)
            .pipe(catchError(this.handleError<School>()));
    }

    getSchoolBySchoolAdminId(id: number | string): Observable<School[]> {
        const url = `${this.endpoint}/schoolAdmin/${id}`;
        return this.httpClient
            .get<School[]>(url)
            .pipe(catchError(this.handleError<School[]>([])));
    }

    importSchoolFile(file: File): Observable<boolean> {
        const formData = new FormData();
        formData.append('Upload', file);
        return this.httpClient
            .post<boolean>(`${this.endpoint}/ImportSchoolsExcel`, formData)
            .pipe(catchError(this.handleError<boolean>()));
    }

    createNewSchool(school: School): Observable<boolean> {
        const url = `${this.endpoint}/createNewSchool`;
        return this.httpInternal
            .postRequest<boolean>(url, school)
            .pipe(catchError(this.handleError<boolean>()));
    }

    editSchoolInformation(school: any): Observable<boolean> {
        const url = `${this.endpoint}/updateSchool`;
        return this.httpClient
            .put<boolean>(url, school)
            .pipe(catchError(this.handleError<boolean>()));
    }

    updateQuizzStatusForAllSchools(
        updateStatus: UpdateStatusForQuizzSchools
    ): Observable<boolean> {
        const url = `${this.endpoint}/quizzAllSchools/updateStatus`;
        return this.httpClient
            .put<boolean>(url, updateStatus)
            .pipe(catchError(this.handleError<boolean>()));
    }

    updateQuizzStatusForSchool(
        updateStatus: UpdateStatusForQuizzSchool
    ): Observable<boolean> {
        const url = `${this.endpoint}/quizzSchool/updateStatus`;
        return this.httpClient
            .put<boolean>(url, updateStatus)
            .pipe(catchError(this.handleError<boolean>()));
    }

    deleteSchool(schoolId: number) {
        const url = `${this.endpoint}/deleteSchool`;
        return this.httpInternal.deleteFullRequest(
            `${url}?schoolId=${schoolId}`
        );
    }

    public getOutsideProgramSchools(programId: number): Observable<{
        data: OutsideProgramSchool[];
    }> {
        return this.httpClient
            .get<{ data: OutsideProgramSchool[] }>(
                `${this.endpoint}/withoutProgramSchools?id=${programId}`
            )
            .pipe(
                catchError(
                    this.handleError<{ data: OutsideProgramSchool[] }>({
                        data: [],
                    })
                )
            );
    }

    public assignShoolToProgram(payload: {
        schoolIds: number[];
        programId: number;
    }): Observable<boolean> {
        const url = `${this.endpoint}/assignProgram?programId=${payload.programId}`;

        return this.httpClient
            .put<boolean>(url, payload.schoolIds)
            .pipe(catchError(this.handleError<boolean>()));
    }

    private handleError<T>(result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            return of((result as T) && error);
        };
    }
}

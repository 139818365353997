<!-- <mat-sidenav-container oncontextmenu="return false" class="font">
 
  <mat-sidenav-content oncontextmenu="return false">
    <router-outlet oncontextmenu="return false"></router-outlet>
  </mat-sidenav-content>
  
  </mat-sidenav-container> -->
<mat-sidenav-container class="font">
 
 

    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
    
    </mat-sidenav-container> 



<div class="activate-class-container h100">

    <div *ngIf='!loading' fxLayout="column" fxLayoutAlign="center center" class="h100">

        <div>
            <img src="../../../assets/img/logo.png" alt="">
        </div>

        <h1>ACTIVATE CLASS</h1>

        <div class="w100" fxLayoutAlign="center center">

            <div fxLayout="column" fxFlex="38%" class="mt16 mb5 pl40">

                <div>

                    <span fxFlex="45%" class="fw500">
                        School Name:
                    </span>

                    <span class="fw100">
                        {{school?.name}}
                    </span>
                </div>

                <div class="mt14">

                    <span fxFlex="45%" class="fw500">
                        Teacher:
                    </span>

                    <span class="fw100">
                        {{teacher?.firstName}} {{teacher?.lastName}}
                    </span>
                </div>

                <div class="mt14">

                    <span fxFlex="45%" class="fw500">
                        Class Name:
                    </span>

                    <span class="fw100">
                        {{currentClass?.name}}
                    </span>
                </div>

                <div class="mt14">

                    <span fxFlex="45%" class="fw500">
                        Number of students:
                    </span>

                    <span class="fw100">
                        {{currentClass?.studentsCount}}
                    </span>
                </div>
            </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-evenly center" class="mt60 w32">
            <div>
                <button (click)='clickNo()' class="btn cancelBtn" fxLayoutAlign="center center" mat-stroked-button>
                    NO
                </button>
            </div>
            <div>
                <button (click)='clickYes()' class="btn applyBtn" fxLayoutAlign="center center" mat-stroked-button>
                    YES
                </button>
            </div>
        </div>
    </div>

    <div *ngIf='loading' fxLayoutAlign="center center" class="w100 h100vh mt7 spinner">
        <mat-spinner></mat-spinner>
    </div>
</div>
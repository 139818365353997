import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSliderModule } from '@angular/material/slider';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginComponent } from './components/login/login.component';
import { MaterialComponentsModule } from './components/common/material-components.module';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { DatePipe } from '@angular/common';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { UpdatePasswordComponent } from './components/forgot-password/update-password/update-password.component';
import { RegistrationTeacherComponent } from './components/registration-teacher/registration-teacher.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginErrorComponent } from './components/login-error/login-error.component';
import { ConfirmationPopupComponent } from './components/confirmation-popup/confirmation-popup.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgImageSliderModule } from 'ng-image-slider';
import { SafePipeModule } from 'safe-pipe';
import { ActivateClassComponent } from './components/activate-class/activate-class.component';
import { ReqistrationErrorComponent } from './components/reqistration-error/reqistration-error.component';
import { WjOlapModule } from '@grapecity/wijmo.angular2.olap';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import {AngularPivotTableModule} from 'angular-pivot-table';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    UpdatePasswordComponent,
    RegistrationTeacherComponent,
    LoginErrorComponent,
    ConfirmationPopupComponent,
    ActivateClassComponent,
    ReqistrationErrorComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialComponentsModule,
    FormsModule,
    FlexLayoutModule,
    MatIconModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MatSliderModule,
    NgImageSliderModule,
    PerfectScrollbarModule,
    SafePipeModule,
    WjOlapModule,
    NgxChartsModule,
    AngularPivotTableModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    [DatePipe]
  ],
  exports: [MaterialComponentsModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { School } from '../shared/models/school/school';
import { Success } from '../shared/models/success/success';
import { NewTeacher } from '../shared/models/teacher/newTeacher';
import { Teacher } from '../shared/models/teacher/teacher';
import { UpdateTeacherSertificate } from '../shared/models/teacher/updateTeacherSertificate';
import { HttpInternalService } from './http-internal.service';

@Injectable({
    providedIn: 'root',
})
export class TeacherService {
    private endpoint = `${environment.hostUrl}/api/teachers`;
    private lol = `${environment.hostUrl}/api/users`;
    private endpointFile = `${environment.hostUrl}/api/File`;

    constructor(
        private httpClient: HttpClient,
        private httpInternal: HttpInternalService
    ) {}

    checkTeacherPermissions(
        teacherId: number
    ): Observable<{ isSuccess: boolean }> {
        return this.httpClient
            .get<{ isSuccess: boolean }>(
                `${this.lol}/checkTeacherOptionalModulPermissions?teacherId=${teacherId}`
            )
            .pipe(
                catchError(
                    this.handleError<{ isSuccess: boolean }>({
                        isSuccess: false,
                    })
                )
            );
    }

    getTeachers(): Observable<Teacher[]> {
        return this.httpClient
            .get<Teacher[]>(this.endpoint)
            .pipe(catchError(this.handleError<Teacher[]>([])));
    }

    getProgramSpecificTeachers(
        programId: number,
        isAll: boolean
    ): Observable<{ data: Teacher[] }> {
        return this.httpClient
            .get<{ data: Teacher[] }>(
                this.endpoint +
                    `/programTeachers?programId=${programId}&isAll=${isAll}`
            )
            .pipe(
                catchError(this.handleError<{ data: Teacher[] }>({ data: [] }))
            );
    }

    getTeachersBySchoolAdmin(id: number): Observable<Teacher[]> {
        return this.httpClient
            .get<Teacher[]>(`${this.endpoint}/schoolAdmin/${id}`)
            .pipe(catchError(this.handleError<Teacher[]>([])));
    }

    getTeacher(id: number): Observable<Teacher> {
        return this.httpClient
            .get<Teacher>(`${this.endpoint}/${id}`)
            .pipe(catchError(this.handleError<Teacher>()));
    }

    TeacherFilter(schoolId: number): Observable<School[]> {
        let url = `${this.endpoint}/TeacherFilter?schoolId=${schoolId}`;
        return this.httpClient
            .get<School[]>(url)
            .pipe(catchError(this.handleError<School[]>([])));
    }

    getTeacherByUserId(id: number | string): Observable<Teacher> {
        return this.httpClient
            .get<Teacher>(`${this.endpoint}/user/${id}`)
            .pipe(catchError(this.handleError<Teacher>()));
    }

    createNewTeacher(teacher: NewTeacher): Observable<any> {
        return this.httpInternal
            .postRequest<any>(this.endpoint, teacher)
            .pipe(catchError(this.handleError<any>()));
    }

    editTeacherInformation(teacher: any): Observable<any> {
        const url = `${this.endpoint}/UpdateTeacher`;
        return this.httpClient
            .put<any>(url, teacher)
            .pipe(catchError(this.handleError<any>()));
    }

    deleteTeacher(teacherId: number) {
        const url = `${this.endpoint}/DeleteTeacher`;
        return this.httpInternal.deleteFullRequest(`${url}?id=${teacherId}`);
    }

    getTeacherBySchoolId(id: number | string): Observable<Teacher[]> {
        return this.httpClient
            .get<Teacher[]>(`${this.endpoint}/GetTeachersBySchool/${id}`)
            .pipe(catchError(this.handleError<Teacher[]>()));
    }

    UpdateTeacherCertificate(
        updateTeacherSertificate: UpdateTeacherSertificate
    ): Observable<Success> {
        const url = `${this.endpoint}/UpdateTeacherCertificate`;
        return this.httpClient
            .put<Success>(url, updateTeacherSertificate)
            .pipe(catchError(this.handleError<Success>()));
    }

    UploadCertificateFile(file: File, teacherId: number): Observable<Success> {
        const formData = new FormData();
        formData.append('file', file);
        return this.httpClient
            .post<Success>(
                `${this.endpointFile}/UploadCertificateFile?teacherId=${teacherId}`,
                formData
            )
            .pipe(catchError(this.handleError<Success>()));
    }

    DownloadCertificateFile(fileId: number) {
        if (fileId) {
            window.open(
                `${this.endpointFile}/DownloadCertificateFile?fileId=${fileId}`
            );
        }
    }

    ChangeFirstTimeSatus(id: number): Observable<Success> {
        const url = `${this.endpoint}/ChangeFirstTimeSatus?teacherId=${id}`;
        return this.httpClient
            .put<Success>(url, id)
            .pipe(catchError(this.handleError<Success>()));
    }

    private handleError<T>(result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            return of(result as T);
        };
    }
}

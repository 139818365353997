import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { UnauthorizedGuard } from './guards/unauthorized.guard';
import { Role } from './helpers/roles';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { UpdatePasswordComponent } from './components/forgot-password/update-password/update-password.component';
import { RegistrationTeacherComponent } from './components/registration-teacher/registration-teacher.component';
import { AuthGuard } from './guards/auth.guard';
import { ActivateClassComponent } from './components/activate-class/activate-class.component';

export const routes: Routes = [
    { path: '', redirectTo: 'sign-in', pathMatch: 'full' },
    { path: 'forgotPassword', component: ForgotPasswordComponent, canActivate: [UnauthorizedGuard] },
    { path: 'updatePassword', component: UpdatePasswordComponent, canActivate: [UnauthorizedGuard] },
    { path: 'teacher-account-request', component: RegistrationTeacherComponent, canActivate: [UnauthorizedGuard] },
    { path: 'activateClass', component: ActivateClassComponent, canActivate: [UnauthorizedGuard] },
    {
        path: 'teacher',
        canActivate: [AuthGuard],
        data: { roles: [Role.Teacher] },
        loadChildren: () =>
            import('./components/teacher/teacher.module').then(
                (m) => m.TeacherModule
            ),
    },
    {
        path: 'student',
        canActivate: [AuthGuard],
        data: { roles: [Role.Student] },
        loadChildren: () =>
            import('./components/student/student.module').then(
                (m) => m.StudentModule
            ),
    },
    {
        path: 'admin',
        canActivate: [AuthGuard],
        data: { roles: [Role.Admin] },
        loadChildren: () =>
            import('./components/admin/admin.module').then(
                (m) => m.AdminModule
            ), 
    },
    {
        path: 'school-admin',
        canActivate: [AuthGuard],
        data: { roles: [Role.SchoolAdmin] },
        loadChildren: () =>
            import('./components/school-admin/school-admin.module').then(
                (m) => m.SchoolAdminModule
            ), 
    },
    {
        path: 'super-admin',
        canActivate: [AuthGuard],
        data: { roles: [Role.SuperAdmin] },
        loadChildren: () =>
            import('./components/super-admin/super-admin.module').then(
                (m) => m.SuperAdminModule
            ), 
    },
    {
        path: 'sign-in',
        component: LoginComponent,
        canActivate: [UnauthorizedGuard],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}

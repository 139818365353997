import { Overlay } from '@angular/cdk/overlay';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/auth.service';
import { SchoolAdminService } from 'src/app/services/school-admin.service';
import { TeacherService } from 'src/app/services/teacher.service';
import { Teacher } from 'src/app/shared/models/teacher/teacher';
import { LoginErrorComponent } from '../login-error/login-error.component';
import { SchoolAdminInstractionComponent } from '../school-admin/school-admin-instraction/school-admin-instraction.component';
import { TeacherInstructionComponent } from '../teacher/teacher-help/teacher-instruction/teacher-instruction.component';

export enum Role {
    Admin = 1,
    Teacher = 2,
    Student = 3,
    SchoolAdmin = 4,
    SuperAdmin = 5,
}

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit, OnDestroy {

    public password: string;
    public username: string;
    
    private unsubscribe$ = new Subject<void>();

    loading: boolean = false;

    constructor(
        private authService: AuthenticationService,
        private router: Router,
        private dialog: MatDialog,
        private overlay: Overlay,
        private teacherService: TeacherService,
        private schoolAdminService: SchoolAdminService,
    ) { }

    public ngOnInit() {
    }

    public signIn() {
        this.loading = true;
        this.authService
            .login({ username: this.username, password: this.password })
            .subscribe(
                (response) => {
                    if (response.roleId == Role.Admin) {
                        this.router.navigate(['/admin', 'my-record'])
                    }
                    if (response.roleId == Role.Teacher) {
                        this.checkFirstTimeTeacher(response.id);
                    }
                    if (response.roleId == Role.Student) {
                        this.router.navigate(['/student', 'quizzes'])
                    }
                    if (response.roleId == Role.SchoolAdmin) {
                        this.checkFirstTimeSchoolAdmin(response.id);
                    }
                    if (response.roleId == Role.SuperAdmin) {
                        this.router.navigate(['/super-admin', 'my-record'])
                    }
                },
                (error) => {
                    this.loading = false;
                    this.username = ''
                    this.password = ''
                    const dialogRef = this.dialog.open(LoginErrorComponent, {
                        width: '250px',
                        scrollStrategy: this.overlay.scrollStrategies.reposition(),
                        minHeight: '200px',
                        height: 'auto',
                        disableClose: true,
                    });
                    dialogRef.afterClosed().subscribe(result => {
                        if (result) {
                            this.router.navigate(['/']);
                        }
                    });
                }
            );
    }

    checkFirstTimeTeacher(id: number) {
        this.teacherService.getTeacherByUserId(id)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((teacher: Teacher) => {
                if (teacher.firstTime) {
                    this.openInstractionForTeacher(teacher.id);
                }
                this.router.navigate(['/teacher', 'my-record'])
            }, (err) => {
                console.log(err);
            });
    }

    checkFirstTimeSchoolAdmin(id: number) {
        this.schoolAdminService.getSchoolAdminById(id)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((admin: any) => {
                if (admin.firstTime) {
                    this.openInstractionForSchoolAdmin(admin.id);
                }
                this.router.navigate(['/school-admin', 'my-record'])
            }, (err) => {
                console.log(err);
            });
    }

    openInstractionForSchoolAdmin(schoolAdminId: number) {
        const dialogRef = this.dialog.open(SchoolAdminInstractionComponent, {
            width: '95vw',
            scrollStrategy: this.overlay.scrollStrategies.reposition(),
            minHeight: '95vh',
            height: 'auto',
            disableClose: true,
            data: { schoolAdminId: schoolAdminId }
        });
    }

    openInstractionForTeacher(teacherId: number) {
        const dialogRef = this.dialog.open(TeacherInstructionComponent, {
            width: '95vw',
            scrollStrategy: this.overlay.scrollStrategies.reposition(),
            height: '95vh',
            disableClose: true,
            data: { teacherId: teacherId }
        });
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ApplicationService } from 'src/app/services/application.service';
import { EmailService } from 'src/app/services/email.service';
import { SchoolService } from 'src/app/services/school.service';
import { UserService } from 'src/app/services/user.service';
import { Application } from 'src/app/shared/models/application/application';
import { NewApplication } from 'src/app/shared/models/application/newApplication';
import { School } from 'src/app/shared/models/school/school';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Overlay } from '@angular/cdk/overlay';
import { MatDialog } from '@angular/material/dialog';
import { ReqistrationErrorComponent } from '../reqistration-error/reqistration-error.component';

@Component({
  selector: 'app-registration-teacher',
  templateUrl: './registration-teacher.component.html',
  styleUrls: ['./registration-teacher.component.css']
})
export class RegistrationTeacherComponent implements OnInit, OnDestroy {

  registrationTeacherForm!: FormGroup;

  schools: School[] = [];
  duration: number = 5000;
  loading: boolean = false;
  loadingSchool: boolean = true;

  showTextAfterSubmit: boolean = false;
  isEmailExist: boolean = false;
  isAccountActive: boolean = false;

  application!: Application;
  applicationSchoolName!: string;
  
  private unsubscribe$ = new Subject<void>();

  constructor(
    private schoolService: SchoolService,
    private applicationService: ApplicationService,
    private snackBar: MatSnackBar,
    private router: Router,
    private emailService: EmailService,
    private userService: UserService,
    private overlay: Overlay,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.initSchools();
    this.registrationTeacherForm = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      schoolId: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      roleId: new FormControl('', [Validators.required]),
    });
  }

  get roleId() { return this.registrationTeacherForm.get('roleId'); }
  get email() { return this.registrationTeacherForm.get('email'); }
  get schoolId() { return this.registrationTeacherForm.get('schoolId'); }
  get lastName() { return this.registrationTeacherForm.get('lastName'); }
  get firstName() { return this.registrationTeacherForm.get('firstName'); }

  initSchools() {
    this.schoolService.getSchools()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.schools = res;
        this.loadingSchool = false;
      }, (err) => {
        console.log(err);
    })
  }

  initSchoolById(id: number) {
    this.schoolService.getSchoolById(id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.applicationSchoolName = res.name;
        this.showTextAfterSubmit = true;
        this.reqistrationError('showTextAfterSubmit');
      }, (err) => {
        console.log(err);
    })
  }

  requestForAccount(registrationTeacherForm: NewApplication) {
    this.loading = true;
    registrationTeacherForm.roleId = Number(registrationTeacherForm.roleId)
    this.userService.emailExist(registrationTeacherForm.email)
      .subscribe((res) => {
        if (res.isEmailExist && !res.isAccountActive) {
          this.isEmailExist = true;
          this.loading = false;
          this.reqistrationError('isEmailExist');
        } else if (res.isAccountActive && res.isEmailExist) {
          this.isAccountActive = true;
          this.loading = false;
          this.reqistrationError('isAccountActive');
        } else if (!res.isAccountActive && !res.isEmailExist) {
          this.applicationService.createNewApplication(registrationTeacherForm)
            .subscribe((res) => {
              if (res) {
                this.initSchoolById(res.schoolId)
                this.application = res;
               
                this.emailService.sendApplicationEmailConfirm({
                  firstName: res.firstName,
                  lastName: res.lastName,
                  emailAddress: res.email,
                  schoolId: res.schoolId
                })
                  .subscribe((res) => {
                    console.log(res);
                  }, (err) => {
                    console.log(err);
                })
                this.snackBar.open('Congratulations!', '', {
                  duration: this.duration
                });
                this.router.navigate(['/teacher-account-request']);
                this.loading = false;
              } else {
                this.snackBar.open('ERROR! Try again.', '', {
                  duration: this.duration
                });
                this.loading = false;
              }
            }, (err) => {
              console.log(err);
          })
        }
      }, (err) => {
        console.log(err);
    })
  }

  reqistrationError(action: string) {
    const dialogRef = this.dialog.open(ReqistrationErrorComponent, {
      width: '57vw',
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      minHeight: '300px',
      height: 'auto',
      disableClose: true,
      data: { action : action, application: this.application, applicationSchoolName: this.applicationSchoolName }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.registrationTeacherForm.reset();
    })
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

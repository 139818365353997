<div class="registration-teacher h100">

    <div fxLayout="column" fxLayoutAlign="center center" class="h100">

        <form class="w70" [formGroup]="registrationTeacherForm" mat-dialog-content>

            <div class="fw600 fs16">Name
                <mat-icon class="icon ac_unitIcon" matSuffix>
                    ac_unit
                </mat-icon>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between center">

                <div fxLayout="column" class="w47">

                    <mat-form-field class="w100">
                        <mat-label>first Name</mat-label>
                        <input type="text" placeholder="first name..." formControlName="firstName" name="name"
                            matInput />
                        <mat-error *ngIf="firstName?.invalid && (firstName?.dirty || firstName?.touched)"
                            class="helper-text red-text mt5">
                            <span *ngIf="firstName?.errors?.required">This field is required.</span>
                        </mat-error>
                    </mat-form-field>

                    <div class="greyColor">First</div>
                </div>

                <div class="w47" fxLayout="column">

                    <mat-form-field class="w100" class="w100">
                        <mat-label>last Name</mat-label>
                        <input type="text" placeholder="last name..." formControlName="lastName" name="name" matInput />
                        <mat-error *ngIf="lastName?.invalid && (lastName?.dirty || lastName?.touched)"
                            class="helper-text red-text mt5">
                            <span *ngIf="lastName?.errors?.required">This field is required.</span>
                        </mat-error>
                    </mat-form-field>
                    <div class="greyColor">Last</div>

                </div>
            </div>

            <div class="fw600 fs16 mt20">Your school email address
                <mat-icon class="icon ac_unitIcon" matSuffix>
                    ac_unit
                </mat-icon>
            </div>

            <mat-form-field class="w100">
                <mat-label>
                    your school email
                    <address></address>
                </mat-label>
                <input type="text" placeholder="Enter your email address..." formControlName="email" name="name"
                    matInput />
                <mat-error *ngIf="email?.invalid && (email?.dirty || email?.touched)" class="helper-text red-text mt5">
                    <span *ngIf="email?.errors?.required">
                        This field is required.
                    </span>
                    <span *ngIf="email?.errors?.email">
                        Enter correct email.
                    </span>
                </mat-error>
            </mat-form-field>

            <div class="fw600 fs16 mt20">Your role
                <mat-icon class="icon ac_unitIcon" matSuffix>
                    ac_unit
                </mat-icon>
            </div>

            <section class="example-section mt10">
                <mat-radio-group fxLayout="column" formControlName="roleId" name="gender" class="ml16">
                    <mat-radio-button class="whiteColor" value="2">
                        Teacher
                    </mat-radio-button>

                    <mat-radio-button class="whiteColor mt10" value="4">
                        School Administrator
                    </mat-radio-button>
                </mat-radio-group>

                <mat-error *ngIf="roleId?.invalid && (roleId?.dirty || roleId?.touched)"
                    class="helper-text red-text mt5">
                    <span *ngIf="roleId?.errors?.required">
                        This field is required.
                    </span>
                </mat-error>
            </section>

            <div class="fw600 fs16 mt20">Select your school
                <mat-icon class="icon ac_unitIcon" matSuffix>
                    ac_unit
                </mat-icon>
            </div>

            <mat-form-field class="w100">
                <mat-label>select your school</mat-label>
                <mat-select formControlName="schoolId" class="whiteColor"
                    [placeholder]="schools.length && !loadingSchool ? 'Select your school...' : loadingSchool ? 'Loading...' : 'No school yet'">
                    <mat-option *ngFor="let school of schools" [value]="school.id">
                        {{school.name}}
                    </mat-option>
                </mat-select>
                <mat-icon *ngIf="schools.length" class="icon" matSuffix>
                    keyboard_arrow_down
                </mat-icon>
                <mat-error *ngIf="schoolId?.invalid && (schoolId?.dirty || schoolId?.touched)"
                    class="helper-text red-text mt5">
                    <span *ngIf="schoolId?.errors?.required">This field is required.</span>
                </mat-error>
            </mat-form-field>
        </form>

        <div fxLayoutAlign="start start" class="mt20 w70">
            <button mat-flat-button class="loginBtn" [disabled]="!registrationTeacherForm.valid"
                (click)="requestForAccount(registrationTeacherForm.value)" mat-stroked-button>
                SUBMIT
            </button>
        </div>

        <div *ngIf='loading' fxLayoutAlign="center " class="w100 mt40 spinner">
            <mat-spinner></mat-spinner>
        </div>
    </div>
</div>
import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TeacherService } from 'src/app/services/teacher.service';

@Component({
  selector: 'app-teacher-instruction',
  templateUrl: './teacher-instruction.component.html',
  styleUrls: ['./teacher-instruction.component.css']
})
export class TeacherInstructionComponent implements OnInit {

  showBtnOk: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<TeacherInstructionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    private teacherService: TeacherService
  ) { }

  ngOnInit(): void {
    setTimeout(() => this.showBtnOk = true, 4000);
  }

  clickClose(){

    this.teacherService.ChangeFirstTimeSatus(this.data.teacherId)
    .subscribe((res) => {
      console.log(res);
    })
    
    this.dialogRef.close();
  }

}

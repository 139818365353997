<div class="login-component h100">

    <div fxLayout="column" fxLayoutAlign="center center" class="h100">

        <div>
            <img src="../../../assets/img/logo.png" alt="">
        </div>

        <div>

            <form name="authForm" #authForm="ngForm" *ngIf='!loading'>

                <div class="title">Login</div>

                <div class="loginBox" fxLayout="column" fxLayoutAlign="center center" mat-dialog-content>

                    <mat-form-field appearance="fill">
                        <mat-label>Username / Email</mat-label>
                        <input class="mt0" type="text" name="authEmail" [(ngModel)]="username" #inEmail="ngModel"
                            placeholder="Enter username or email..." matInput />
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>Password</mat-label>
                        <input class="mt0" type="password" name="authPassword" [(ngModel)]="password"
                            #upPassword="ngModel" placeholder="Enter password..." matInput autocomplete="on" />
                    </mat-form-field>
                </div>
            </form>

            <div *ngIf='!loading' fxLayout="row" fxLayoutAlign="space-around center" class="mt16">
                <div class="greenButton cursor" [routerLink]="['/forgotPassword']">
                    Forgot password
                </div>
            </div>

            <div *ngIf='!loading' fxLayoutAlign="center center">
                <button mat-flat-button class="loginBtn" (click)="signIn()" mat-stroked-button>
                    Login
                </button>
            </div>

            <div *ngIf='loading' fxLayoutAlign="center " class="w100 mt40 spinner">
                <mat-spinner></mat-spinner>
            </div>
        </div>



        <div class="w mt14 w100" fxLayoutAlign="center center">
            This initiative is supported by funding from the <br>
            Queensland Government's Community Road Safety Grants scheme.
        </div>


    </div>
</div>
<div class="school-admin-instruction-component h100"  fxLayout="column" fxLayoutAlign="space-around center">

    <iframe class="w100" src="https://www.brake.org.au/html/welcome-schoolAdmin.html" frameborder="0"></iframe>

    <div class="w100 mt60 h40" fxLayout="row" fxLayoutAlign="center center">
        <button *ngIf='showBtnOk' (click)='clickClose()' class="btnStyle">
            CLOSE
        </button>
    </div>
</div>

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Application } from '../shared/models/application/application';
import { NewApplication } from '../shared/models/application/newApplication';
import { UpdateStatusApplication } from '../shared/models/application/updateStatusApplication';
import { HttpInternalService } from './http-internal.service';

export enum ApplicationRole {
  Teacher = 2,
  SchoolAdmin = 4,
}

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  public endpoint: string = `${environment.hostUrl}/api/applications`;

  constructor(
    private httpClient: HttpClient,
    private httpInternal: HttpInternalService
  ) { }

  getApplications(): Observable<Application[]> {
    return this.httpClient.get<Application[]>(this.endpoint)
      .pipe(
        catchError(this.handleError<Application[]>([]))
      );
  }

  getApplicationsByStatus(status: string): Observable<Application[]> {
    return this.httpClient.get<Application[]>(`${this.endpoint}/GetApplicationsByStatus?status=${status}`)
      .pipe(
        catchError(this.handleError<Application[]>([]))
      );
  }

  getApplication(id: number | string): Observable<Application> {
    const url = `${this.endpoint}/${id}`;
    return this.httpClient.get<Application>(url)
      .pipe(
        catchError(this.handleError<Application>())
      );
  }

  //Application Status = Pending | Successful | Unsuccessful
  updateStatusApplication(updateStatusApplication: UpdateStatusApplication): Observable<Application> {
    const url = `${this.endpoint}/updateStatus`;
    return this.httpClient.put<Application>(url, updateStatusApplication)
      .pipe(
        catchError(this.handleError<Application>())
      );
  }

  createNewApplication(newApplication: NewApplication): Observable<Application> {
    const url = `${this.endpoint}`;
    return this.httpInternal.postRequest<Application>(url, newApplication)
      .pipe(
        catchError(this.handleError<Application>())
      );
  }

  private handleError<T>(result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    }
  }
}

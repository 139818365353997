import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/auth.service';
import { User } from '../shared/models/user';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, OnDestroy {

    user!: User;
    private unsubscribe$ = new Subject<void>();

    constructor(private router: Router,
        private authService: AuthenticationService,
    ) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (localStorage.length) {
            if (localStorage.getItem('role')) {
                if (route.data.roles && route.data.roles.indexOf(Number(localStorage.getItem('role'))) === -1) {
                    this.router.navigate(['/']);
                }
            } else {
                this.authService.getUser()
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe((res: User) => {
                        this.user = res;
                        localStorage.setItem('role', String(this.user.roleId));
                    });
            }
            return true;
        }
        this.router.navigate(['/sign-in']);
        return false;
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ClassService } from '../../services/class.service';
import { SchoolService } from '../../services/school.service';
import { TeacherService } from '../../services/teacher.service';
import { Class } from '../../shared/models/class/class';
import { Teacher } from '../../shared/models/teacher/teacher';
import { School } from '../../shared/models/school/school';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EmailService } from 'src/app/services/email.service';
import { QuizSrudentsService } from 'src/app/services/quiz-srudents.service';

@Component({
  selector: 'app-activate-class',
  templateUrl: './activate-class.component.html',
  styleUrls: ['./activate-class.component.css']
})
export class ActivateClassComponent implements OnInit, OnDestroy {

  activationCode: string;
  classId: number;

  currentClass: Class;
  teacher: Teacher;
  school: School;

  duration: number = 5000;
  loading: boolean = false;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private location: Location,
    private classService: ClassService,
    private schoolService: SchoolService,
    private teacherService: TeacherService,
    private snackBar: MatSnackBar,
    private router: Router,
    private emailService: EmailService,
    private quizSrudentsService: QuizSrudentsService
  ) { }

  ngOnInit(): void {
    const string = this.location.path();
    this.activationCode = string.split('activationCode=').pop();
    this.classId = Number(string.split('activationCode=')[0].split('classId=').pop().slice(0, -1));
    this.getClassById();
  }

  getClassById() {
    if (this.classId) {
      this.classService.getClass(this.classId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res) => {
          this.currentClass = res;
          this.getSchool();
          this.getTeacher();
        }, (err) => {
          console.log(err);
        })
    }
  }

  getSchool() {
    this.schoolService.getSchoolById(this.currentClass?.schoolId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.school = res;
      }, (err) => {
        console.log(err);
      })
  }

  getTeacher() {
    this.teacherService.getTeacher(this.currentClass?.teacherId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.teacher = res;
      }, (err) => {
        console.log(err);
      })
  }

  clickNo() {
    this.loading = true;
    this.classService.declineClassFree(this.classId)
      .subscribe((res) => {
        this.snackBar.open(res.responseMessage, '', {
          duration: this.duration
        });
        if (res.isSuccess) {
          this.changeIsSendRequestStatus();
          this.router.navigate(['/sign-in']);
        }
        this.loading = false;
      }, (err) => {
        console.log(err);
      })
  }

  changeIsSendRequestStatus() {
    this.classService.ChangeIsSendRequestStatus(this.classId, false)
      .subscribe((res) => {
        console.log(res);
      }, (err) => {
        console.log(err);
      })
  }

  clickYes() {
    this.loading = true;
    
    this.classService.activateClassFree(this.activationCode, this.classId)
      .subscribe((res) => {
        if (res.isSuccess) {
          this.router.navigate(['/sign-in']);
          this.sendClassActivateInfo();
          this.assingeStudentsUponClassCreated();
        }
        this.snackBar.open(res.responseMessage, '', {
          duration: this.duration
        });
        this.loading = false;
      }, (err) => {
        console.log(err);
      })
  }

  assingeStudentsUponClassCreated() {
    this.quizSrudentsService.AssingeStudentsUponClassCreated(this.classId)
      .subscribe((res) => {
        console.log(res);
      }, (err) => {
        console.log(err);
      })
  }

  sendClassActivateInfo() {
    this.emailService.SendClassActivateInfo({
      classId: this.classId
    })
      .subscribe((res) => {
        console.log(res);
      }, (err) => {
        console.log(err);
      })
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
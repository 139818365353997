import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Role } from '../helpers/roles';
import { AuthenticationService } from '../services/auth.service';
import { User } from '../shared/models/user';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UnauthorizedGuard implements CanActivate, OnDestroy {

    private unsubscribe$ = new Subject<void>();

    constructor(private router: Router,
        private authService: AuthenticationService,) { }

    public canActivate() {
        if (this.authService.areTokensExist()) {
            this.authService.getUser()
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((response: User) => {
                    if (response) {
                        if (response.roleId == Role.Admin) {
                            this.router.navigate(['/admin', 'my-record'])
                        }
                        if (response.roleId == Role.Teacher) {
                            this.router.navigate(['teacher/my-record']).then(r => r);
                        }
                        if (response.roleId == Role.Student) {
                            this.router.navigate(['/student', 'quizzes'])
                        }
                        if (response.roleId == Role.SchoolAdmin) {
                            this.router.navigate(['/school-admin', 'my-record'])
                        }
                        if (response.roleId == Role.SuperAdmin) {
                            this.router.navigate(['/super-admin', 'my-record'])
                        }
                    }
                });
            return false;
        }
        return true;
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
